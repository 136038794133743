<template>
  <div class="h-auto bg-darkgray text-white" :class="{'hidden': excludePaths.includes($route.name), 'laptop:block': excludePaths.includes($route.name)}">
    <div class="container text-white w-full mx-auto px-10 py-10 flex flex-col items-start justify-between gap-4 md:flex-row md:items-center md:gap-0">
      <div class="flex items-center">
        <div>
          <p class="">Designed by <a class="text-highlight hover:text-white" :href="$t('links.puntoquindici').toString()" target="_blank">Puntoquindici</a></p>
          <p class="text-xs font-hairline hidden md:block mt-4">Puntoquindici srl - VAT ID: 04509530236</p>
          <p class="text-xs font-hairline hidden md:block">&copy; Copyright 2024. All rights reserved.</p>
        </div>
        <!-- <div class="hidden ml-16 xl:flex ">
          <div class="flex">
            <a :href="$t('links.linkedin').toString()" target="_blank">
              <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/linkedin.svg" alt="Linkedin">
            </a>
          </div>
          <div class="flex">
            <a :href="$t('links.instagram').toString()" target="_blank">
              <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/instagram.svg" alt="Instagram">
            </a>
          </div>
          <div class="flex">
            <a :href="$t('links.youtube').toString()" target="_blank">
              <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/ico-youtube.svg" alt="YouTube">
            </a>
          </div>
        </div> -->
      </div>
      <div class="flex justify-center items-center gap-1">
        <div class="flex">
          <a :href="$t('links.linkedin').toString()" target="_blank" title="My AR Studio Linkedin">
            <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/linkedin.svg" alt="Linkedin">
          </a>
        </div>
        <div class="flex">
          <a :href="$t('links.instagram').toString()" target="_blank" title="My AR Studio Instagram">
            <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/instagram.svg" alt="Instagram">
          </a>
        </div>
        <div class="flex">
          <a :href="$t('links.youtube').toString()" target="_blank" title="My AR Studio YouTube">
            <img loading="lazy" class="mr-2 xl:mx-2" src="~/assets/img/ico-youtube.svg" alt="YouTube">
          </a>
        </div>
      </div>
      <div class="uppercase flex flex-col items-start justify-start gap-2">
        <NuxtLink
          :to="localePath($t('menu.contacts.path').toString())"
          :title="$t('menu.contacts.title').toString()"
          class="block text-sm xl:mb-0 xl:flex xl:mx-30 hover:text-highlight tracking-widest"
        >
          {{ $t('menu.contacts.menu-entry').toString() }}
        </NuxtLink>
        <a class="block text-sm xl:mb-0 xl:flex xl:mx-30 hover:text-highlight tracking-widest" :href="$t('links.privacy-policy').toString()" target="_blank">Privacy policy / Impressum</a>
        <a class="block text-sm xl:mb-0 xl:flex xl:mx-30 hover:text-highlight tracking-widest iubenda-cs-preferences-link cursor-pointer">Cookie preferences</a>
      </div>
      <div class="md:hidden">
        <p class="text-xs font-hairline">Puntoquindici srl - VAT ID: 04509530236</p>
        <p class="text-xs font-hairline">&copy; Copyright 2024. All rights reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  data() {
    return {
      excludePaths: ['examples-merryar']
    }
  }
})
</script>

<style scoped>
 p {
  @apply text-white
 }
</style>
