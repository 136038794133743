<template>
  <div id="app" :class="{ 'overflow-hidden': menuIsOpen }">
    <MARSNavbar v-if="showNavbar" @open="menuIsOpen = true" @close="menuIsOpen = false" />
    <!-- <transition> causes error on DOM not matching from server to client -->
    <!-- <router-view /> -->
    <slot />
    <MARSFooter />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue'
import { installIubenda, useIubendaStore } from 'mars-iubenda-vite'
import { useRuntimeConfig, useRoute, useHead } from '#imports'

const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const iubendaStore = useIubendaStore()

const hideNavBarOnRoutes = [
  'starter-kit',
  'free-demo'
]

const menuIsOpen = ref(false)

const { locale, t, tm, rt, te } = useI18n()

// Computed properties
const showNavbar = computed(() => {
  if (route && route.name) {
    return !hideNavBarOnRoutes.some(name => (route.name! as string).startsWith(name))
  }
  return true
})

const pageName = computed(() => {
  return (route.name! as string).split('___')[0]
})

// Watchers
watch(() => route.name, () => {
  console.log('Route changed:', route.name)
})

watch(() => locale, () => {
  console.log('Changed locale: force reload for Iubenda')
  setTimeout(() => {
    location.reload()
  }, 1000)
})

const i18nHead = useLocaleHead({
  addSeoAttributes: true
}) 

const siteConfig = useSiteConfig()

// Head setup
useHead(() => {
  if(te(`menu.${pageName.value}`)) {
  // Customize head data based on the current page and locale
    const pageMeta = tm(`menu.${pageName.value}`) as {
      "path": string,
      "menu-entry": string,
      "title": string,
      "description": string
    }
    return {
      title: computed( () => rt(pageMeta.title) ),
      titleTemplate: (titleChunk) => {
        // if(titleChunk) {
        //   if(titleChunk.indexOf(siteConfig.name) != -1) { 
        //     // title already contains site name
        //     return titleChunk
        //   } else {
        //     // title does not contain site name: add it
        //     return `${titleChunk} | ${siteConfig.name}`
        //   }
        // }
        // // no title: set site name
        // return siteConfig.name
        return titleChunk || siteConfig.name
      },
      htmlAttrs: {
        lang: i18nHead.value.htmlAttrs.lang
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { hid: 'description', name: 'description', content: computed( () => rt(pageMeta.description) ) },
        // open-graph social card -> using nuxtseo
        // { hid: 'og:type', property: 'og:type', content: 'website' },
        // { hid: 'og:title', property: 'og:title', content: computed( () => rt(pageMeta.title), ) },
        // { hid: 'og:description', property: 'og:description', content: computed( () => rt(pageMeta.description) ) },
        // { hid: 'og:image', property: 'og:image', content: runtimeConfig.public.i18n.baseUrl + '/img/myarstudio-og-social.jpg' },
        // { hid: 'og:url', property: 'og:url', content: runtimeConfig.public.i18n.baseUrl + route.path },
        // // twitter social card -> using nuxtseo but it seems to have only partial support
        { hid: 'twitter:card', property: 'twitter:card', content: 'summary' },
        { hid: 'twitter:site', property: 'twitter:site', content: runtimeConfig.public.i18n.baseUrl + route.path },
        { hid: 'twitter:title', property: 'twitter:title', content: computed( () => rt(pageMeta.title) ) },
        { hid: 'twitter:description', property: 'twitter:description', content: computed( () => rt(pageMeta.description) ) },
        // { hid: 'twitter:image', property: 'twitter:image', content: runtimeConfig.public.i18n.baseUrl + '/img/myarstudio-twitter-social.jpg' },
        ...(i18nHead.value.meta || [])
      ],
      link: [
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
        // TODO: check trailing slash?
        // { rel: 'canonical', href: runtimeConfig.public.i18n.baseUrl + route.path },
        ...(i18nHead.value.link || [])
      ]
    }
  }
  return {}
})

// Mounted hook
onMounted(() => {
  const p = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
  window.addEventListener('resize', p)
  p()

  installIubenda(route.query.locale, true, iubendaStore)

  // iubendaStore.setConsetGivenPurpose(3, true)
  // setTimeout(() => {
  //   iubendaStore.setConsetGivenPurpose(2, true)
  // }, 2000)
})

</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 1s;
    transition-property: opacity;
    transition-timing-function: ease;
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
</style>
