<template>
  <div class="">
    <!-- Help desk topbar -->
    <div class="flex items-center justify-center flex-wrap p-2 md:p-3 text-lightgray bg-backgroundgray uppercase tracking-wide text-sm">
      <span class="hidden md:block text-xs md:text-sm pr-1" v-html="$t('miscellaneous.help-desk').toString()" />
      <span v-html="$t('miscellaneous.help-desk-hours').toString()" />
      <img src="~/assets/img/telephone.svg" class="ml-5 mr-1" alt="Telephone">
      <a class="md:font-bold text-standard flex" :href="'tel:'+$t('miscellaneous.help-desk-phone')" v-html="$t('miscellaneous.help-desk-phone').toString()" />
    </div>

    <div v-if="menuOpen" class="left-0 top-0 h-0 md:h-screen w-0 md:w-1/2 z-50 fixed" @click="closeMenu" />
    <!-- Side panel menu -->
    <div
      class="overflow-auto fixed w-full sm:w-auto sm:max-w-sm right-0 h-screen bg-darkgray z-50 duration-500 ease-in-out transform transition-transform animated"
      :class="{ 'translate-x-full': !menuOpen }"
    >
      <div class="flex flex-col">
        <div class="close-btn w-full my-4 pr-4 right-0 flex justify-end">
          <img class="p-4 cursor-pointer" src="~/assets/img/btn-close.svg" alt="Close menu" @click="menuOpen = false">
        </div>
        <div class="h-full flex flex-col justify-center pr-16 text-white">
          <ul v-if="showMenuItems" class="ml-12">
            <li v-for="entry of menuEntries" :key="entry.name">
              <NuxtLink v-if="$t(`menu.${entry.name}.path`).toString().startsWith('/')"
                :to="$t(`menu.${entry.name}.path`).toString()"
                :title="$t(`menu.${entry.name}.title`).toString()"
                class="navbar-compact"
                @click.native="closeMenu"
              >
                {{ $t(`menu.${entry.name}.menu-entry`).toString() }}
              </NuxtLink>
              <NuxtLink v-else-if="!entry.children"
                :to="localePath($t(`menu.${entry.name}.path`).toString())"
                class="navbar-compact"
                activeClass="text-highlight"
                :title="$t(`menu.${entry.name}.title`).toString()"
                @click.native="closeMenu"
              >
                {{ $t(`menu.${entry.name}.menu-entry`).toString() }}
              </NuxtLink>
              <template v-else>
                <p class="inline-block mt-0 mx-15 tracking-wide bold-size m-6 uppercase italic"
                   :class="parentMenuActive(entry.children) ? 'text-highlight' : 'text-white'"
                >
                  {{ $t(`menu.${entry.name}.menu-entry`).toString() }}
                </p>
                <ul class="pl-8">
                  <li v-for="entry2 of entry.children" :key="`${entry.name}_${entry2.name}`">
                    <NuxtLink
                      :to="localePath($t(`menu.${entry2.name}.path`).toString())"
                      :title="$t(`menu.${entry2.name}.title`).toString()"
                      class="navbar-compact"
                      activeClass="text-highlight"
                      @click.native="closeMenu"
                    >
                      {{ $t(`menu.${entry2.name}.menu-entry`).toString() }}
                    </NuxtLink>
                  </li>
                </ul>
              </template>
            </li>
          </ul>

          <ul class="ml-12 mt-20 mb-8">
            <li v-if="showMenuItems">
              <a
                :href="panelLinks.registerUrl.value"
                :title="$t('menu.try-it.title').toString()"
                target="_blank"
                class="inline-block mt-0 text-orange mx-15 uppercase tracking-wide bold-size m-6"
              >
                {{ $t("menu.try-it.menu-entry").toString() }}
              </a>
            </li>
            <li v-if="showMenuItems">
              <a
                :href="panelLinks.loginUrl.value"
                :title="$t('menu.login.title').toString()"
                class="navbar-compact"
              >
                {{ $t('menu.login.menu-entry').toString() }}
              </a>
            </li>
            <li class="mx-15">
              <div class="flex gap-4">
                <Icon class="h-6 w-6" name="fontisto:world-o"></Icon>
                <span v-for="(locale) in $i18n.locales" :key="locale.code" class="uppercase">
                  <NuxtLink :to="switchLocalePath(locale.code)" :title="$t('language', locale).toString()" class="hover:text-highlight" :class="{'text-highlight': locale.code === $i18n.locale}">
                    {{ locale.code.toUpperCase() }}
                  </NuxtLink>
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Full width header menu -->
    <nav class="flex xl:grid xl:grid-cols-8 items-center justify-between p-4 w-full bg-white"
         :class="{ 'fixed top-0': !atTopOfPage && !menuOpen, 'scrolled': !atTopOfPage, 'staging': isStaging }"
    >
      <div class="flex items-center flex-shrink-0 text-white">
        <a href="/" :title="$t('menu.index.title').toString()">
          <span class="font-semibold text-xl tracking-tight">
            <img
              class="hidden lg:block"
              height=""
              src="~/assets/img/logo.svg"
              alt="My AR Studio logo"
            >
            <img
              class="md:hidden"
              style="height:45px"
              src="~/assets/img/logo.svg"
              alt="My AR Studio logo"
            >
            <img
              class="hidden md:block lg:hidden"
              style="height:55px"
              src="~/assets/img/logo.svg"
              alt="My AR Studio logo"
            >
          </span>
        </a>
      </div>
      <div class="block xl:hidden">
        <button
          v-if="showMenuItems"
          class="flex items-center px-1 py-2 text-standard hover:text-highlight"
          @click="openMenu"
        >
          <img src="~/assets/img/burger.svg" alt="Open Menu">
        </button>
      </div>
      <div
        class="hidden xl:block xl:items-center xl:align-center xl:justify-center w-auto xl:col-span-5"
      >
        <div
          v-if="showMenuItems"
          class="text-sm flex xl:items-center xl:align-center xl:justify-center xl:flex-wrap"
        >
          <template v-for="entry of menuEntries">
            <NuxtLink v-if="$t(`menu.${entry.name}.path`).toString().startsWith('/')" :key="entry.name"
              :to="$t(`menu.${entry.name}.path`).toString()" external
              :title="$t(`menu.${entry.name}.title`).toString()"
              class="navbar-full"
            >
                {{ $t(`menu.${entry.name}.menu-entry`).toString() }}
            </NuxtLink>
            <NuxtLink v-else-if="!entry.children" :key="'e1'+entry.name"
              :to="localePath($t(`menu.${entry.name}.path`).toString())"
              activeClass='text-highlight'
              :title="$t(`menu.${entry.name}.title`).toString()"
              class="navbar-full"
            >
                {{ $t(`menu.${entry.name}.menu-entry`).toString() }}
            </NuxtLink>
            <PopoverHover v-else>
              <template #label>
                <span :class="{ 'text-highlight': parentMenuActive(entry.children!) }"> {{ $t(`menu.${entry.name}.menu-entry`).toString() }} </span>
                <svg class="text-gray-400 ml-2 h-5 w-8 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
                </svg>
              </template>
              <template #default>
                <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div class="relative grid gap-4 bg-white px-5 py-6">
                    <NuxtLink v-for="entry2 of entry.children" :key="entry.name+'_'+entry2.name"
                      v-slot="{ href, navigate, isActive, isExactActive }"
                      :to="localePath($t(`menu.${entry2.name}.path`).toString())"
                      class="navbar-full"
                      activeClass="text-highlight"
                      :title="$t(`menu.${entry2.name}.title`).toString()"
                    >
                      {{ $t(`menu.${entry2.name}.menu-entry`).toString() }}
                    </NuxtLink>
                  </div>
                </div>
              </template>
            </PopoverHover>
          </template>
        </div>
      </div>

      <div class="hidden xl:block flex-shrink-0 text-white xl:col-span-2">
        <div class="text-sm flex justify-end text-center">
          <a
            v-if="showMenuItems"
            :href="panelLinks.registerUrl.value"
            target="_blank"
            :title="$t('menu.try-it.title').toString()"
            class="h-full my-auto text-orange font-bold mx-15 uppercase tracking-wide bold-size w-min"
          >
            {{ $t('menu.try-it.menu-entry').toString() }}
          </a>

          <a
            v-if="showMenuItems"
            :href="panelLinks.loginUrl.value"
            :title="$t('menu.login.title').toString()"
            class="h-full my-auto text-standard mx-15 uppercase tracking-wide bold-size hover:text-highlight hover:font-bold"
          >
            {{ $t('menu.login.menu-entry').toString() }}
          </a>

          <PopoverHover>
            <template #label>
              <Icon class="h-6 w-6" name="fontisto:world-o"></Icon>
              <span class="pl-2" :title="$t('language').toString()">{{ $i18n.locale.toUpperCase() }}</span>
              <svg class="text-gray-400 h-5 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
              </svg>
            </template>
            <template #default>
              <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div v-for="(locale) in $i18n.locales.filter( (l)=> l.code != $i18n.locale )" :key="locale.code" class="text-standard uppercase relative grid gap-4 bg-white px-5 py-3 hover:text-highlight">
                  <NuxtLink :to="switchLocalePath(locale.code)" :title="$t('language', locale).toString()">{{ locale.code.toUpperCase() }}</NuxtLink>
                </div>
              </div>
            </template>
          </PopoverHover>
        </div>
      </div>

    </nav>

    <!-- banner -->
    <div v-if="bannerOpen" class="relative" style="max-width: 1280px; margin: auto;">
      <img v-if="$i18n.locale=='it'" src="~/assets/img/banner/BlackFriday2022.it.jpg">
      <img v-else src="~/assets/img/banner/BlackFriday2022.jpg">
      <button class="close-btn w-full mt-8 pr-8 absolute top-0 right-0 flex justify-end">
        <img src="~/assets/img/btn-close.svg" @click="bannerOpen = false">
      </button>
    </div>
    <!--    <div class="bg-merryar text-white text-center text-xl flex align-middle justify-center px-2" v-if="currentPage !=='examples-merryar'">-->
    <!--      <img src="~/assets/img/merryar/ico-gift.svg" />-->
    <!--      <p class="inline-block mx-4 my-4">{{$t('banner.merryar-1')}} 
      <NuxtLink class="underline" :to="localePath( 'examples-merryar')">{{$t('banner.merryar-2')}}</NuxtLink>
       {{$t('banner.merryar-3')}}</p>-->
    <!--      <img src="~/assets/img/merryar/ico-tree.svg" />-->
    <!--    </div>-->

    <div style="height:93.6px" :class="{'hidden': atTopOfPage,'block': !atTopOfPage}" />
  </div>
</template>

<script lang="ts">
declare global {
  interface Window {}
}

interface MenuEntry {
  name: string
  children?: MenuEntry[]
}
</script>
<script setup lang="ts">
  // Imports
  import _ from 'lodash'
  import { defineEmits, defineProps, withDefaults } from 'vue';
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/24/solid'
  import { GlobeAltIcon } from '@heroicons/vue/24/outline'
  import { usePanelLinks } from '~/composables/PanelLinks';
  
  const route = useRoute()
  const localePath = useLocalePath()
  const switchLocalePath = useSwitchLocalePath()
  const panelLinks = usePanelLinks()

  // Props
  const props = withDefaults(defineProps<{
    showMenuItems?: boolean
  }>(), {
    showMenuItems: true
  })

  // Emits
  const emit = defineEmits(['open', 'close'])


  // Reactive variables
  const isStaging = ref(false)
  const menuOpen = ref(false)
  const atTopOfPage = ref(true)
  const bannerOpen = ref(false)

  // Non reactive variables
  const menuEntries = [
    { name: 'index' },
    { name: 'how-to-start' },
    {
      name: 'features',
      children: [
        { name: 'virtualization' },
        { name: 'how-it-works' },
        { name: 'viewer-3d-ar' },
        { name: 'editor' },
        { name: 'animation' },
      ]
    },
    { name: 'examples' },
    { name: 'services' },
    { name: 'pricing' },
    { name: 'learn' },
    { name: 'explore' },
  ]

  const baseUrl = process.client ? document.location.protocol + document.location.host : process.env.BASE_URL

  // Functions
  // the function to call when the user scrolls, added as a method
  function handleScroll(e: WheelEvent) {
    if (e.deltaY && e.deltaY < 0) {
      if (window.pageYOffset > 0) {
        atTopOfPage.value = false
      } else {
        atTopOfPage.value = true
      }
    } else {
      atTopOfPage.value = true
    }
  }

  function openMenu() {
    menuOpen.value = true
    emit('open')
    fixChatZIndex()
  }
  function fixChatZIndex() {
    const chat = document.querySelector('#chat-widget-container') as HTMLElement
    if (chat) {
      chat.style.setProperty('z-index', '30')
    }
  }
  function closeMenu() {
    menuOpen.value = false
    emit('close')
  }

  function parentMenuActive(menuChildren: {name: string}[]) {
    return menuChildren.map(mc => mc.name).some(word => (route.name as string)?.startsWith(word)) || false
  }

  // LifeCycle hooks
  onBeforeMount( ()=> {
    window.addEventListener('wheel', handleScroll)
    isStaging.value = window.location.hostname === 'www.dev.myarstudio.cloud' // || window.location.hostname === 'localhost'
  })
</script>

<style lang="css" scoped>
.mobile-navbar {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}
nav {
    z-index: 30;
}
nav.scrolled {
    @apply shadow-2xl;
    /* border-bottom: 0px; */
}
.navbar-full {
  @apply inline-block mt-0 hover:text-highlight mx-15 uppercase tracking-wide text-center
}
.navbar-full-submenu {
  @apply inline-block mt-0 text-standard hover:text-highlight mx-15 uppercase tracking-wide whitespace-nowrap
}
.navbar-compact {
  @apply inline-block mt-0 hover:text-highlight mx-15 uppercase tracking-wide m-6
}

.staging {
  background: repeating-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 15px,
    rgba(255, 235, 0, 0.2) 15px,
    rgba(255, 235, 0, 0.2) 30px
  );
}
</style>
