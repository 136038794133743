<template>
  <div class="relative">
    <button type="button"
      class="flex items-center mt-0 text-standard hover:text-highlight hover:fill-highlight pr-0 uppercase tracking-wide bold-size text-center"
      @mouseover="openMenu(true)" @mouseleave="openMenu(false, false)"
    >
    <slot name="label"></slot>
    </button>
    <div v-if="openedMenu" class="absolute left-1/2 z-40 -translate-x-1/2 transform px-2 sm:px-0"
      @mouseover="openMenu(true)" @mouseleave="openMenu(false)">
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'

const openedMenu = ref(false)

function openMenu(bOpen: boolean, bImmediate = true) {
  openMenuDebounced.cancel()
  if (bImmediate) {
    openedMenu.value = bOpen
  } else {
    openMenuDebounced(bOpen)
  }
}

const openMenuDebounced = _.debounce((bOpen: boolean) => {
  console.log('error', bOpen)
  openMenu(bOpen)
}, 500)

</script>
